import { render, staticRenderFns } from "./Metric.vue?vue&type=template&id=9ab36052&scoped=true&"
import script from "./Metric.vue?vue&type=script&lang=js&"
export * from "./Metric.vue?vue&type=script&lang=js&"
import style0 from "./Metric.vue?vue&type=style&index=0&id=9ab36052&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ab36052",
  null
  
)

export default component.exports