<template>
    <div class="single-metric">
        <span class="metric-title">
            {{ title }}
        </span>
        <h4 class="metric-value">
            {{ metric }}
        </h4>
    </div>
</template>

<script>

export default {
    name: 'Metric',
    props: {
        title: {
            type: String,
            required: true
        },
        metric: {
            type: [Number, String],
            default: 0,
            required: false
        }
    }
};
</script>

<style lang="scss" scoped>
.metric {
    text-align: center;
    padding: 15px 0;
}

.metric-title {
    font-size: 12px;
    text-transform: uppercase;
    color: $light-grey-title;
}

.metric-value {
    line-height: 34px;
    font-size: 28px;
    color: #03A9F2;
    font-weight: 600;
}
</style>
