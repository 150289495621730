<template>
    <div
        class="billboard"
        :class="size">
        <metric
            v-for="(metric,m) in metrics"
            :key="m"
            class="metric"
            :title="metric.alias || metric.name"
            :metric="metric.formatted" />
    </div>
</template>

<script>
import Metric from './Metric';
export default {
    name: 'Billboard',
    components: {
        Metric
    },
    props: {
        metrics: {
            type: Object,
            required: true
        }
    },
    computed: {
        size() {
            const size = Object.keys(this.metrics).length;
            if( size <= 6) {
                return 'quarter';
            } else if(size <= 12) {
                return 'half';
            } else {
                return 'full';
            }

        }
    }
};
</script>

<style lang="scss" scoped>
.billboard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    &.quarter {
        .metric {
            width: 50%;
        }
    }
    &.half,
    &.full {
        .metric {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 80px;
            width: calc(100%/6);
            border-right: 1px solid $gray-light;
            &:nth-of-type(6n) {
                border-right: none;
            }
        }
    }
    &.half {
        .metric {
            width: 15%;
        }
    }
    &.full {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .metric {
            width: 15%;
        }

    }
}

</style>